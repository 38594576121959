
import { useChartResources, useChartTasks } from '@chart';

const intervalAdapter = useChartTasks('intervalAdapter');

export default function (ticker, interval, resolution, onHistoryCallback, onErrorCallback) {

    const bar = useChartResources('Bar');

    bar.setFilters({
        symbol: ticker.market === 'crypto'
            ? `${ticker.symbol}${ticker.currency_code}`
            : ticker.symbol,
        base: ticker.symbol,
        counter: ticker.currency_code,
        market: ticker.market,
        interval: intervalAdapter(interval)
    });

    let request;
    if (resolution.firstDataRequest) {
        bar.state = [];
        request = bar.load();
    } else {
        request = bar.refill({
            from: resolution.from,
            to: bar.state[0].time / 1000
        });
    }

    const stateLength = bar.state.length;

    request.then(response => {
        bar.state.length > stateLength
            ? onHistoryCallback(bar.state, { noData: false })
            : onHistoryCallback([], { noData: true })
    }).catch(() => {
        onHistoryCallback([], { noData: false });
        onErrorCallback('Klines data error');
    }).finally(() => {

    });
}