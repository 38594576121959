<script setup>

import { computed } from 'vue';

const logo = computed(() => {
    let template = '';
    switch (true) {
        case /xxxxxx/.test(location.hostname):
            template = `<img src="${require('@assets/img/tipitek-full-logo.svg')}" alt="LOGO"/>`;
            break;
        case /exness/.test(location.hostname):
            template = '<span>EXNESS</span> <span>INVEST</span>';
            break;
        default:
            template = `<img src="${require('@assets/img/tms.svg')}" alt="LOGO"/>`;
            break;
    }

    return template;
});

</script>

<template>
    <div class="cover">
        <div class="overlay">
            <img v-bind:src="require('@assets/img/bg.jpg')"/>
            <div class="logo" v-html="logo"/>
        </div>
        <div class="form">
            <slot name="form"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.cover {
    height: 100vh;
    overflow: auto;
    color: var(--home-page-color, $cover-color);
}

.overlay {
    height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }
}

.logo {
    z-index: 1;
}

.form {
    z-index: 2;
    position: relative;
    box-shadow: -5px 0 30px -10px #000000;
    background-image: linear-gradient(to right, #000000, $bar-bg);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 745px) {
    .cover {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }

    .overlay {
        flex-grow: 1;
        align-items: center;
        justify-content: start;
    }

    .logo {
        max-width: 640px;
    }

    .form {
        width: 30%;
        min-width: 380px;
    }
}

@media (max-width: 744px) {
    .overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        align-items: start;
    }

    .logo {
        width: 250px;
        margin-top: 40px;
    }

    .form {
        width: 94%;
        min-width: 320px;
        max-width: 405px;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        border-radius: 9px;
    }
}
</style>