<template>
    <currency-sprite/>

    <router-view/>
    <g-preloader-overlay v-if="STATE.IS_LOADING"/>
    <g-popup v-if="PopUp.component.value">
        <component v-bind:is="PopUp.component.value" v-bind="PopUp.payload" v-on:performed="PopUp.close()"></component>
    </g-popup>
</template>

<script>

import { watch } from 'vue';
import { useRouter } from 'vue-router';
import CurrencySprite from '@/Ship/components/currency-sprite';
import { PopUpManager as PopUp, STATE } from '@/Ship';

export default {
    name: 'Tipitek-APP',
    head: {
        link: [
            {
                rel: 'icon',
                href: require('@/Ship/assets/img/tipitek-white-logo.svg')
            },
        ]
    },
    components: {
        CurrencySprite,
    },

    setup() {

        const router = useRouter();

        watch(() => STATE.IS_LOGIN, () => {
            if (!STATE.IS_LOGIN) {
                router.push({ name: 'SignIn', params: router.currentRoute.value.params });
            }
        });

        document.title = {
            'trade.tipitek.com': "Tipitek",
            'front.tipitek.ovh': "DevTipitek",
            'exnesstrader.com': "Exness Invest",
        }[location.hostname] || 'Trade Terminal';

        return { PopUp, STATE };
    },
};
</script>
<style lang="scss">
@import './assets/index';

.hide-sprite {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}
</style>
