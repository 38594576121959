<script setup>

import { useRouter } from 'vue-router';
import { computed, ref, reactive, watch } from 'vue';
import { useAuthComponents, useAuthActions } from '@auth';
import { i18n, PopUpManager as PopUp, verifyField } from '@/Ship';
import { useProfileActions } from '@profile';

const { signUp, signIn } = useAuthActions();
const getCountryResource = useProfileActions('getCountryResource');

const PasswordInput = useAuthComponents('PasswordInput');

const countries = getCountryResource();
const router = useRouter();

const isAllow = ref();
const isComplex = ref(false);

const payload = reactive({
    first_name: '',
    last_name: '',
    phone: '',
    country_code: '',
    email: '',
    password: '',
    domain: location.host,
});

const errors = reactive({
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    country_code: null,
    password: null,
});

const firstName = computed({
    get: () => payload.first_name,
    set: value => {
        payload.first_name = value;
        errors.first_name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const lastName = computed({
    get: () => payload.last_name,
    set: value => {
        payload.last_name = value;
        errors.last_name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const email = computed({
    get: () => payload.email,
    set: value => {
        payload.email = value;
        errors.email = verifyField(value, ['require', 'email']);
        return true;
    }
});

const countryCode = computed({
    get: () => payload.country_code,
    set: value => {
        payload.country_code = value;
        errors.country_code = verifyField(value, ['require']);
        return true;
    }
});

const phone = computed({
    get: () => payload.phone,
    set: value => {
        payload.phone = value;
        errors.phone = verifyField(value, ['require', 'phone']);
        return true;
    }
});

const password = computed({
    get: () => payload.password,
    set: value => {
        payload.password = value;
        errors.password = verifyField(value, ['minLength:8']);
        return true;
    }
});

watch([errors, payload], () => {
    isAllow.value = true;
    for (const key in errors) {
        if (errors[key] !== null || !payload[key].length) {
            isAllow.value = false;
            break;
        }
    }
});

function registration() {
    if (isAllow.value) {
        signUp(payload).then(() => {
            signIn(payload.email, payload.password).then(() => {
                router.push({ name: 'Trading', params: router.currentRoute.value.params });
            }).catch(() => {
                PopUp.open(require('@auth/components/signUpInfoPopUp'))
                    .setCaption(i18n.global.t('base.info'))
                    .setPayload({
                        payload: {
                            mainText: i18n.global.t('base.something_wrong'),
                            additionText: i18n.global.t('auth.user_already_registered')
                        }
                    });
            });
        });
    }
}
</script>

<template>
    <form class="sign-up" v-on:submit.prevent="registration">
        <div>
            <g-caption size="3" weight="700">{{ $t('auth.sign_up', 'Sign Up') }}</g-caption>
            <div class="subtitle">{{ $t('auth.sign_sub_title') }}</div>
        </div>

        <div>
            <g-field v-model="firstName"
                     v-bind:label="$t('profile.first_name', 'First Name')"
                     v-bind:error="errors.first_name"/>
            <g-field v-model="lastName"
                     v-bind:label="$t('profile.last_name', 'Last Name')"
                     v-bind:error="errors.last_name"/>

            <g-phone-input v-model="phone"
                           v-model:country="countryCode"
                           v-bind:label="$t('profile.phone', 'Phone')"
                           v-bind:countries="countries.state"
                           v-bind:error="errors.phone"/>

            <g-field type="email"
                     v-model="email"
                     v-bind:label="$t('profile.email', 'Email')"
                     v-bind::error="errors.email"/>

            <hr class="separator">

            <div class="subtitle">{{ $t('auth.reset_sub_title_password') }}</div>
            <password-input v-model="password"
                            v-model:complex="isComplex"
                            v-bind:label="$t('profile.password')"
                            v-bind:error="errors.password"/>
        </div>

        <g-button v-bind:disabled="!isAllow">
            {{ $t('auth.create_account', 'Create Account') }}
        </g-button>

        <div>
            {{ $t('auth.sign_have_account') }}
            <router-link v-bind:to="{ name: 'SignIn', params: { locale: $i18n.locale } }" tag="a" class="link">
                {{ $t('auth.sign_in', 'Sign In') }}
            </router-link>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.sign-up {
    padding: 0 18px;
    max-width: 460px;
    box-sizing: border-box;

    & > div {
        margin: 25px 0;
    }
}

.g-checkbox {
    vertical-align: top;
}

.g-button {
    width: 100%;
    background-color: var(--login-btn, $login-btn);

    &:hover {
        background-color: var(--login-btn-hover, $login-btn-hover);
    }

    &:active {
        background-color: var(--login-btn-active, $login-btn-active);
    }
}

.g-field,
.g-input,
.password-input {
    margin: 8px 0 10px;
    padding-top: 1px;
}

.g-phone-input {
    margin-top: 18px;
}

.link {
    margin: 0 5px;
    font-size: 106%;
    font-weight: 600;
    white-space: nowrap;
    color: #ee3356;
    transition: color 0.2s;

    &:hover {
        color: var(--primary, $primary);
    }
}

.subtitle {
    color: var(--title-text-color, $title-text-color);
}

.separator {
    height: 1px;
    border: none;
    margin-top: 20px;
    background-color: var(--separator-color, $separator-color);
}
</style>
