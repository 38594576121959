<script setup>

import { defineProps, defineEmits, watch, ref, computed } from 'vue';
import { STATE } from '@/Ship';
import { useQuoteActions } from '@quotes';

defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const {
    getMarketResource,
    getCryptoResource,
    getStockResource,
    getCommodityResource,
    getForexResource,
} = useQuoteActions();

const market = getMarketResource();

const resource = {
    crypto: getCryptoResource,
    stocks: getStockResource,
    commodities: getCommodityResource,
    forex: getForexResource,
}[STATE.MARKET]();

const tab = ref('crypto');

const timeOffset = new Date().getTimezoneOffset() * 60000;

const markets = computed(() => {
    const tabList = {};
    market.state.forEach(item => {
        tabList[item.name] = item.name;
    });

    return tabList;
});

const disables = computed(() => {
    return market.state.filter(item => item.status === 'closed').map(item => item.name);
});

watch(() => resource.isLoaded, () => {
    if (resource.isLoaded) {
        market.state.forEach(market => {
            if (market.status === 'closed' && !!market.openingTime) {
                market.openingTime *= 1000;
                market.countdown = market.openingTime - Date.now();
                let interval = setInterval(() => {
                    market.countdown = market.countdown - 1000;
                    market.openingTimer = new Date(market.countdown + timeOffset).toLocaleTimeString();
                    if (market.countdown < 0) {
                        delete market.openingTime;
                        market.status = 'opened';
                        clearInterval(interval);
                    }
                }, 1000);
            }
        });
    }
});
</script>

<template>
    <div class="markets">
        <g-tabs v-model="tab"
                v-bind:tab-list="markets"
                v-bind:disables="disables"
                v-on:change="$emit('update:modelValue', tab)"/>
    </div>
</template>

<style lang="scss" scoped>


</style>
