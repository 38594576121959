<template>
    <!-- Other Tickers -->
    <div class="tickers">
      <ticker v-for="ticker in tickers"
              v-bind:key="ticker.name"
              v-bind:ticker="ticker"
              v-bind:data-base="ticker.base"
              v-bind:data-counter="ticker.counter"
              v-bind:data-market="ticker.market"
              v-on:click="chooseQuote"/>
    </div>
</template>

<script>

import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import { STATE, WebSockets as Stream } from '@/Ship';
import { useQuoteActions, useQuoteComponents } from '@quotes';
import { useChartActions } from '@chart';

const {
    sortBy,
    getMarketResource,
    getForexResource,
    resolveQuotesStream
} = useQuoteActions();

const getBarResource = useChartActions('getBarResource');

export default {
    name: 'Forex',
    components: {
        Ticker: useQuoteComponents('Ticker')
    },

    props: ['symbol', 'sortable', 'search'],

    setup(props) {

        const bar = getBarResource();

        const markets = getMarketResource();
        const resource = getForexResource();

        const counterCurrency = ref('USD');

        const tickers = computed(() => {
            const clonedQuotes = _.cloneDeep(resource.state.filter(item => {
                const searchFilter = props.search
                    ? item.symbol.base.includes(props.search.toUpperCase())
                    : true;

                //return (
                //    searchFilter &&
                //    item.symbol !== props.symbol
                //)

                return (
                    searchFilter &&
                    (item.symbol !== props.symbol)
                );
            }));

            // Sorting
            clonedQuotes.sort(
                sortBy(props.sortable || 'price')
            );

            return ref(clonedQuotes).value;
        });

        const router = useRouter();

        function chooseQuote({ currentTarget: { dataset: {base, counter} } }) {
            bar.state = [];
            bar.loaded.value = false;

            STATE.MARKET = 'forex';
            markets.choose('forex');
            resource.choose(`${base}${counter}`)


            router.push({ params: {
                market: 'forex',
                base: base.toLowerCase(),
                counter: counter.toLowerCase(),
            } });
        }

        /** Stream **/
        const stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

        onMounted(() => {
            stream.subscribe({ quotes: { market: 'forex' }});
            stream.addHandlers({
                forex: resolveQuotesStream,
            });
        });

        onUnmounted(() => {
            stream.unsubscribe('quotes');
        });

        return {
            tickers,
            counterCurrency,
            chooseQuote,
        };
    }
};
</script>
