<template>
    <div class="wrapper">
        <div class="bg-overlay"></div>
        <topbar/>
        <environment/>
    </div>
</template>

<script>

import { useEnvComponents } from '@overlay';

const Topbar = useEnvComponents('Topbar');

export default {
    name: 'Layout',
    components: {
        Topbar,
    }
};
</script>

<style lang="scss">
.topbar {
    width: 100%;
    height: var(--topbar-height, $topbar-height);
}

.environment {
    overflow: auto;
    //height: calc(100vh - var(--bar-height, $bar-height));
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 1ex;
        background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    }
}

.bg-overlay {
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-image: linear-gradient(to left, #0a1132, #000000);
    //background-image: radial-gradient(#0a1132 40%, #000000 100%);
}

@media (min-width: 785px) {
    .bg-overlay {
        clip-path: polygon(60% 0, 100% 0, 100% 100%, 10% 100%);
    }
}

@media (max-width: 784px) {
    .bg-overlay {
        clip-path: polygon(100% 10%, 100% 100%, 0 100%, 0 60%);
    }
}
</style>
