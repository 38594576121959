<template>
    <!-- Favorite Tickers -->
    <!--
    <ticker v-for="ticker in tickers.filter(item => item.isFavorite)"
            v-bind:key="ticker.symbol"
            v-bind:ticker="ticker"
            v-bind:data-symbol="ticker.symbol"
            v-bind:data-market="ticker.market"
            v-on:click="chooseQuote"/>
    -->

    <div class="toolbar">
        <search v-model="searchString"/>
    </div>

    <!-- Other Tickers -->
    <div class="tickers">
        <ticker v-for="ticker in tickers"
                v-bind:key="ticker.name"
                v-bind:ticker="ticker"
                v-bind:data-base="ticker.base"
                v-on:click="chooseQuote"/>
    </div>

</template>

<script>

import { ref, computed, onUnmounted, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import { STATE, WebSockets as Stream } from '@/Ship';
import { useQuoteActions, useQuoteComponents } from '@quotes';
import { useChartActions } from '@chart';

const {
    sortBy,
    getMarketResource,
    getStockResource,
    resolveQuotesStream,
} = useQuoteActions();

const getBarResource = useChartActions('getBarResource');

const {
    Ticker,
    Search,
} = useQuoteComponents();

export default {
    name: 'Stocks',
    components: {
        Ticker,
        Search,
    },

    props: ['symbol', 'search'],

    setup(props) {

        const country = ref('US');
        const searchString = ref('');
        const sortable = ref('name');

        const bar = getBarResource();

        const markets = getMarketResource();
        const resource = getStockResource();

        const tickers = computed(() => {
            const clonedQuotes = _.cloneDeep(resource.state.filter(item => {
                const searchFilter = searchString.value
                    ? item.base.includes(searchString.value.toUpperCase())
                    : true;

                return (
                    searchFilter && item.asset.symbol !== props.symbol.base
                    //&& item.asset.country === country.value
                );
            }));

            // Sorting
            clonedQuotes.sort(
                sortBy(sortable.value)
            );

            return ref(clonedQuotes).value;
        });

        const router = useRouter();

        function chooseQuote({ currentTarget: { dataset: {base} } }) {
            bar.state = [];
            bar.loaded.value = false;

            STATE.MARKET = 'stocks';
            STATE.SYMBOL = `${base}USDT`;
            resource.choose(STATE.SYMBOL);
            markets.choose('stocks');

            router.push({ params: {
                market: 'stocks',
                base: base.toLowerCase(),
                counter: 'usdt',
            } });
        }

        /** Stream **/
        const stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

        onMounted(() => {
            stream.subscribe({ quotes: { market: 'stocks' }});
            stream.addHandlers({
                stocks: resolveQuotesStream,
            });
        });
        onUnmounted(() => {
            stream.unsubscribe('quotes');
        });

        return {
            sortable,
            country,
            tickers,
            searchString,
            chooseQuote,
        };
    }
};
</script>

<style lang="scss" scoped>
.toolbar {
    padding: 0 10px;
}

@media (min-width: 645px) {
    .tickers {
        overflow: auto;
        height: calc(100% - 76px);
    }
}
</style>