
//import { useShipActions } from '@/Ship';
import { useQuoteActions } from '@quotes';
import { useProfileActions } from '@profile';
import { useChartActions } from '@chart';
import _ from 'lodash';

const getProfileResource = useProfileActions('getProfileResource');

const {
    updateBar,
    getBarResource,
} = useChartActions();

const {
    getCryptoResource,
    getStockResource,
    getCommodityResource,
    quoteNormalization,
    getForexResource,
} = useQuoteActions();

const resources = {
    Crypto: getCryptoResource,
    Stocks: getStockResource,
    Commodities: getCommodityResource,
    Forex: getForexResource,
};

//const probability = useShipActions('probability');

export default function(quotes, market) {

    market = _.upperFirst(market.toLowerCase());
    const resource = resources[market]();

    const profile = getProfileResource();

    for (const key in quotes.stream) {
        const index = resource.state.findIndex(quote => (quote.base === quotes.stream[key].pair.base && quote.counter === quotes.stream[key].pair.counter));

        if (index >= 0) {
            const quote = quotes.stream[key];
            
            const multiplier = (parseInt(profile.multipliers[`${quote.pair.base}${quote.pair.counter}`]) || 0) / 100;
            const normalQuote = quoteNormalization(quotes.stream[key], multiplier);
			
            Object.assign(resource.state[index], normalQuote);
			
            const bar = getBarResource();
            if (bar.symbol === `${quote.pair.base}${quote.pair.counter}`) {

                updateBar({
                    source: 'resolveQuoteStream',
                    time: Date.now(),
                    high: normalQuote.high,
                    low: normalQuote.low,
                    open: normalQuote.open,
                    price: normalQuote.price,
                    volume_24: normalQuote.volume_24,
                });
            }
        }
    }

    // quotes.stream.forEach(item => {
    //     const index = resource.state.findIndex(quote => (quote.base === item.pair.base && quote.counter === item.pair.counter));
    //
    //     if (index >= 0) {
    //         Object.assign(resource.state[index], quoteNormalization(item, factor));
    //     }
    // });
}