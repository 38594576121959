<template>
    <div class="trading">
        <g-tabs v-model="tab" v-bind:tab-list="{Positions: 'Positions', History: 'History'}"/>

        <component v-bind:is="tab" class="trading"/>
    </div>
</template>

<script>

import { ref } from 'vue';
import { usePositionComponents } from '@position';

const {
    Positions,
    History,
} = usePositionComponents();

export default {
    components: {
        Positions,
        History,
    },

    setup() {
        const tab = ref('Positions');

        return {
            tab,
        };
    }
};

</script>

<style scoped lang="scss">
.g-tabs {
    max-width: 320px;
}

.trading {
    border: 1px solid var(--separator-color, $separator-color);
    box-shadow: 0 5px 20px -5px #000000aa;
}
</style>