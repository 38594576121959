
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

export default function (position) {
    const entryPrice = Number(position.entry_price);
    const multiplier = (parseInt(profile.multipliers[position.pair_symbol]) || 0) / 100;
    const marketPrice = Number(position.marketPrice) + (position.marketPrice * multiplier);
    const swap = ((position.usdt_amount * position.swap) / 100) * (position.counterPrice || 1);

    const pl = position.direction === 'Long'
        ? ((marketPrice - entryPrice) * position.counterPrice * Number(position.value) - swap).toFixed(2)
        : ((entryPrice - marketPrice) * position.counterPrice * Number(position.value) - swap).toFixed(2);

    let tradingFee = 0;
    switch(position.pair.market) {
        case 'crypto':
            tradingFee = profile.crypto_fee || 0.001;
            break;
        case 'stocks':
            tradingFee = profile.stock_fee || 0.001;
            break;
        case 'commodities':
            tradingFee = profile.commodity_fee || 0.001;
            break;
        default:
            tradingFee = 0.001;
    }

    const fee = position.usdt_amount * (tradingFee || 0.001) / 100;

    position.pl = Number((pl - fee).toFixed(2));
}