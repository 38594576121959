<script setup>

import { defineProps } from 'vue';
import { useOrderBookActions } from '@order-book';

defineProps(['base', 'counter']);

const getTradeResource = useOrderBookActions('getTradeResource');
const trades = getTradeResource();

</script>

<template>
    <div class="trades">
        <table>
            <thead>
            <tr>
                <th class="price">Price</th>
                <th class="amount">Amount ({{ counter }})</th>
                <th class="total">Total ({{ base }})</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(trade, i) in trades.state" v-bind:key="`trade-${i}`" v-bind:class="[trade.type]">
                <td class="price">{{ trade.price }}</td>
                <td class="amount">{{ trade.amount }}</td>
                <td class="total">{{ trade.total }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
.trades {
    width: 100%;
    overflow: auto;
    height: calc(99% - var(--bar-height, $bar-height));
    background-color: var(--env-bg, $env-bg);
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 1ex;
        background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    }
}

table {
    width: 100%;
    font-size: 13px;
    position: relative;
}

thead {
    top: 0;
    z-index: 2;
    position: sticky;
    background-color: var(--bar-bg, $bar-bg);

    th {
        padding: 8px 15px;
        font-size: 13px;
    }
}

td {
    padding: 3px 15px;
}

.price {
    text-align: left;
}

.amount {
    text-align: center;
}

.total {
    text-align: right;
}

@media (min-width: 895px) {
    .trades {
        min-height: 524px;
    }
}
</style>
