<template>
    <cover>
        <template v-slot:form>
            <component v-bind:is="$route.name"/>
        </template>
    </cover>
</template>

<script>


import { useAuthComponents } from '@auth';
import { useCoverComponents } from '@overlay';

export default {
    name: 'Layout',

    components: {
        Cover: useCoverComponents('Cover'),
        SignIn: useAuthComponents('SignIn')
    }
};
</script>

<style lang="scss" scoped>

</style>