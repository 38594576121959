<template>
    <div class="password-input">
        <g-field v-bind:label="label"
                 v-model:modelValue="modelValue"
                 v-on:input="inputEvent"
                 v-bind:type="showPassword ? 'text' : 'password'"
                 autocomplete="new-password">
            <g-symbol name="refresh"
                      width="20"
                      height="20"
                      v-on:click="generate"/>
            <g-symbol :name="showPassword ? 'eye-open' : 'eye-close'"
                      width="20"
                      height="20"
                      v-on:click="showPassword = !showPassword"/>
        </g-field>

        <ul class="password-level" v-bind:class="status">
            <li v-bind:class="{ active: level & 1 }"></li>
            <li v-bind:class="{ active: level & 2 }"></li>
            <li v-bind:class="{ active: level & 4 }"></li>
            <li v-bind:class="{ active: level & 8 }"></li>
        </ul>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useAuthActions } from '@auth';

export default {
    name: 'PasswordInput',
    emits: ['update:modelValue', 'update:complex'],
    props: {
        modelValue: {
            type: String,
        },
        label: {
            type: String,
        },
        complex: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const {
            validatePassword,
            generatePassword
        } = useAuthActions();

        const level = ref(0);
        const showPassword = ref(false);

        const status = computed(() => {
            return {
                0: 'danger',
                1: 'danger',
                3: 'danger',
                7: 'warning',
                15: 'success',
            }[level.value];
        });

        const inputEvent = event => {
            emit('update:modelValue', event.target.value);
            validate(event.target.value);
        };

        function generate() {
            const password = generatePassword(10);
            emit('update:modelValue', password);
            validate(password);
        }

        function validate(password) {
            level.value = validatePassword(password);
            emit('update:complex', Boolean(level.value & 8));
        }

        return {
            level,
            status,
            showPassword,
            generate,
            inputEvent,
        };
    },
};
</script>
<style lang="scss" scoped>
.password-input {
    position: relative;

    &:focus-within {
        svg {
            fill: var(--primary, $primary);
        }
    }

    &:deep(.g-input > span) {
        padding-right: 10px;
    }
}

.generate-btn {
    margin-right: 1rem;
    fill: var(--main-text-color, $main-text-color);

    &:active {
        fill: var(--primary, $primary);
    }
}

.show-btn {
    fill: var(--main-text-color, $main-text-color);

    &:active {
        fill: var(--primary, $primary);
    }
}

.password-level {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > li {
        margin: 0 4px;
        flex-grow: 1;
        height: 6px;
        border-radius: 3px;

        &:not(.active) {
            background-color: var(--separator-color, $separator-color);
        }
    }

    &.success > li.active {
        background-color: var(--success, $success);
    }

    &.warning > li.active {
        background-color: var(--warning, $warning);
    }

    &.danger > li.active {
        background-color: var(--danger, $danger);
    }
}
</style>