<script setup>

import { defineEmits, defineProps, watch } from 'vue';

const props = defineProps({
    tabList: {
        type: Object,
    },
    disables: {
        type: Array,
        default: [],
    },
    modelValue: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['update:modelValue', 'change']);
watch(() => props.modelValue, () => {
    emit('change', true);
});

</script>

<template>
    <div class="g-tabs">
        <div v-for="(value, key) in tabList"
             v-bind:key="key"
             class="tab"
             v-bind:class="[{'active': key === modelValue }, {disabled: disables.includes(key)}]"
             v-on:click="emit('update:modelValue', key)">
            {{ value }}
            <g-symbol name="lock" v-if="disables.includes(key)" width="16" height="16"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.g-tabs {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: start;
    font-family: $font-title;
    height: var(--bar-heigh, $bar-height);

    .tab {
        flex-grow: 1;
        height: 100%;
        line-height: var(--bar-heigh, $bar-height);
        text-align: center;
        font-weight: 500;
        font-size: 1.3rem;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        color: var(--main-text-color, $main-text-color);
        border-radius: var(--bar-rounding, $bar-rounding);

        //&:not(:first-child),
        //&:not(:last-child) {
        //    margin: 0 -10px;
        //    clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 100%, 8px 100%);
        //}
        //
        //&:first-child {
        //    margin: 0 -10px 0 0;
        //    clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 100%, 0 100%);
        //}
        //
        //&:last-child {
        //    margin: 0 0 0 -10px;
        //    clip-path: polygon(0 0, 100% 0, 100% 100%, 8px 100%);
        //}

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }

        &.active {
            background-color: var(--primary, $primary);
            color: var(--title-text-color, $title-text-color);
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.g-symbol {
    fill: var(--disabled-color, $disabled-color);
}
</style>