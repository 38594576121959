<script setup>

import { computed, ref, defineEmits, defineProps } from 'vue';
import { usePositionActions } from '@position';

const props = defineProps({
    payload: {
        type: Object
    }
});

const emit = defineEmits(['performed']);

const getPositionResource = usePositionActions('getPositionResource');
const positions = getPositionResource();

const position = computed(() => {
	const position = positions.find(props.payload.id);
	if (!position) {
		emit('performed');
	}

	return position || {};
});

const showErrorAlert = ref(false);
const isDisabled = ref(!position.value.id);

const confirm = () => {
    isDisabled.value = true;
    showErrorAlert.value = false;
    positions.delete(props.payload.id, {status: 'trader_close'}).then(() => {
        positions.state.find(position => position.id === props.payload.id).locked = true;
        emit('performed');
    }).catch(() => {
        isDisabled.value = false;
        showErrorAlert.value = true;
    });
};
</script>

<template>
    <form class="confirm-popup" v-on:submit.prevent="confirm">
        <div v-if="showErrorAlert" class="alert">
            <g-caption size="5" align="center">{{ $t('trading.not_closed_alert', 'The position was not closed') }}.</g-caption>
            <g-caption size="4" align="center">{{ $t('base.try_again', 'Try again') }}</g-caption>
        </div>
        <ul class="content">
            <li>
                <div class="title">
                    {{ $t('trading.currency_pair') }}
                </div>
                <div class="value">
                    <g-flex align="center" justify="start" gap="5">
                        <g-symbol-currency v-bind:name="position.pair.base.toLowerCase()" width="22" height="22"/>
                        <span><b>{{ position.pair.base }}</b>:{{ position.pair.counter }}</span>
                    </g-flex>
                </div>
            </li>
            <li>
                <div class="title">
                    {{ $t('trading.type') }}
                </div>
                <div class="value">
                    <b v-bind:class="position.direction.toLowerCase()">{{ position.direction }}</b>
                </div>
            </li>
            <li>
                <div class="title">
                    {{ $t('trading.own_funds') }}
                </div>
                <g-flex class="value" align="flex-start" gap="5">
                    {{ Number(position.usdt_amount) }}
                    <g-symbol-currency name="usdt" width="20" height="20"/>
                    <template v-if="position.pair.counter !== 'USDT'">
                        / {{ Number(position.amount) }}
                        <g-symbol-currency v-bind:name="position.pair.counter.toLowerCase()" width="20" height="20"/>
                    </template>
                </g-flex>
            </li>
            <li v-if="position.leverage > 1">
                <div class="title">
                    {{ $t('trading.leverage') }}
                </div>
                <g-flex class="value leverage"  align="flex-start" gap="5">
                    ✕{{ position.leverage }}
                </g-flex>
            </li>
            <li>
                <div class="title">
                    {{ $t('trading.quantity') }}
                </div>
                <g-flex class="value"  align="flex-start" gap="5">
                    {{ Number(position.total_amount) }}
                    <g-symbol-currency v-bind:name="position.pair.counter.toLowerCase()" width="20" height="20"/>
                </g-flex>
            </li>
            <li>
                <div class="title">
                    {{ $t('trading.value') }}
                </div>
                <g-flex class="value"  align="flex-start" gap="5">
                    {{ Number(position.value) }}
                    <g-symbol-currency v-bind:name="position.pair.base.toLowerCase()" width="20" height="20"/>
                </g-flex>
            </li>

            <li>
                <div class="title">
                    {{ $t('trading.swap') }}
                </div>
                <g-flex class="value" align="flex-start" gap="5">
                    {{ (position.amount * position.swap).toFixed(2) }}
                    <g-symbol-currency v-bind:name="position.pair.counter.toLowerCase()" width="20" height="20"/>
                </g-flex>
            </li>
            <li>
                <div class="title">
                    {{ $t('trading.profit') }}
                </div>
                <g-flex class="value" v-bind:class="position.pl > 0 ? 'increase' : 'decrease'" align="flex-start" gap="5">
                    {{ position.pl }}
                    <g-symbol-currency name="usdt" width="20" height="20"/>
                </g-flex>
            </li>
        </ul>
        <div class="footer">
            <g-button class="cancel" type="button" v-on:click="$emit('performed')">
                {{ $t('base.cancel') }}
            </g-button>
            <g-button class="confirm"
                      type="submit"
                      v-bind:class="[position.pl > 0 ? 'increase' : 'decrease', 'decrease', {disabled: isDisabled}]">
                {{ $t('base.confirm') }}
            </g-button>
        </div>
    </form>
</template>

<style scoped lang="scss">
.confirm-popup {
    width: 380px;
    max-width: 100%;
}

.content {
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 10px 0;
        font-size: 1.1rem;
    }

    .title {
        color: var(--label-color, $label-color);
    }

    .value {
        color: var(--title-text-color, $title-text-color);

        &.increase {
            color: var(--increase, $increase);
        }

        &.decrease {
            color: var(--decrease, $decrease);
        }

        &.leverage {
            color: var(--warning-hover, $warning-hover);
        }
    }
}

.alert {
    padding: 10px;
    background-color: var(--danger, $danger);
    color: var(--title-text-color, $title-text-color);
}

.footer {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

    & > button {
        flex-grow: 1;
        border: 1px solid;
        background-color: transparent;

        &.cancel {
            color: var(--main-text-color, $main-text-color);
            border-color: var(--main-text-color, $main-text-color);

            &:hover {
                color: white;
                background-color: var(--main-text-color, $disabled-color);
            }
        }

        &.increase {
            color: var(--increase, $increase);
            border-color: var(--increase, $increase);

            &:hover {
                color: white;
                background-color: var(--increase, $increase);
            }
        }

        &.decrease {
            color: var(--decrease, $decrease);
            border-color: var(--decrease, $decrease);

            &:hover {
                color: white;
                background-color: var(--decrease, $decrease);
            }
        }
    }
}
</style>
